import React from "react"
import Seo from "../../components/seo"

import Events from "../../components/Content/Events"

export default props => (
  <React.Fragment>
    <Seo title={`Eventos`} />
    <Events location={props.location} langKey={props.pageContext.langKey} />
  </React.Fragment>
)
